import React, { useRef, useCallback } from 'react';

import { FiCheckCircle } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Form } from './styles';
import Modal from '../Modal';
import Button from '../Button';
import getValidationErrors from '../../utils/getValidationErrors';

import InputForm from '../Input/InputForm';
import SelectInput from '../Input/SelectInput';

interface IUser {
  id: string;
  name: string;
  email: string;
  password: string;
  permission: string;
}

interface ICreateUser {
  name: string;
  email: string;
  password: string;
  permission: string;
}

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  handleAddUser: (post: Omit<IUser, 'id'>) => void;
}

const ModalAddUser: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  handleAddUser,
}) => {
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: ICreateUser) => {
      try {
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string().required('E-mail Obrigatório'),
          permission: Yup.string().required('Permissão obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setIsOpen();

        handleAddUser(data);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
      }
    },
    [handleAddUser, setIsOpen],
  );

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <h1>Novo Usuário</h1>
        <div className="pl-lg-4">
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <p className="form-control-label">Nome</p>
                <InputForm name="name" className="form-control" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <p className="form-control-label">E-mail</p>
                <InputForm name="email" className="form-control" />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-group">
                <p className="form-control-label">Permissão</p>
                <SelectInput name="permission" className="form-control">
                  <option value="admin">Administrador</option>
                  <option value="salesperson">Vendedor</option>
                  <option value="marketing">Marketing</option>
                </SelectInput>
              </div>
            </div>
          </div>
          <Button type="submit">
            Criar usuário
            <div className="icon">
              <FiCheckCircle size={24} />
            </div>
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalAddUser;
