import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './routes';

import SignIn from '../pages/SignIn';

import Posts from '../pages/Posts';
import Profile from '../pages/Profile';
import Users from '../pages/Users';
import PointCard from '../pages/PointCard';
import Products from '../pages/Products';
import CreatePost from '../pages/Posts/CreatePost';
import EditPost from '../pages/Posts/EditPost';
import UserPointCard from '../pages/UserPointCard';
import MainCategories from '../pages/mainCategories';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />

    <Route path="/posts" component={Posts} isPrivate />
    <Route path="/novo-post" component={CreatePost} isPrivate />
    <Route path="/editar-post/:post+" component={EditPost} isPrivate />
    <Route path="/perfil" component={Profile} isPrivate />
    <Route path="/usuarios" component={Users} isPrivate />
    <Route path="/cartoes-pontos" component={PointCard} isPrivate />
    <Route path="/cartao-ponto/:user+" component={UserPointCard} isPrivate />
    <Route path="/produtos" component={Products} isPrivate />
    <Route path="/categorias" component={MainCategories} isPrivate />
  </Switch>
);

export default Routes;
