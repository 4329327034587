import React, { useEffect, useState } from 'react';
import { FiTrash2 } from 'react-icons/fi';
import { FaHome } from 'react-icons/fa';

import { Link } from 'react-router-dom';
import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';

import SideBar from '../../components/Sidebar';
import Header from '../../components/Header';
import api from '../../services/api';
import ModalAddUser from '../../components/ModalAddUser';

import { Unauthorized } from './styles';
import ModalEditUser from '../../components/ModalEditUser';

interface IUser {
  id: string;
  name: string;
  email: string;
  password: string;
  permission: string;
}

interface ICreateUser {
  name: string;
  email: string;
  password: string;
  permission: string;
}

const Users: React.FC = () => {
  const { addToast } = useToast();
  const { user } = useAuth();

  const [users, setUsers] = useState<IUser[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [editingUser, setEditingUser] = useState<IUser>({} as IUser);
  const [editModalOpen, setEditModalOpen] = useState(false);

  useEffect(() => {
    api.get('/users').then(response => {
      setUsers(response.data);
    });
  }, []);

  async function handleAddUser(newUser: ICreateUser): Promise<void> {
    try {
      const response = await api.post('/users', newUser);

      const newProduct = response.data;

      setUsers([...users, newProduct]);

      addToast({
        type: 'success',
        title: 'Usuário criado com sucesso',
      });
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro na criação do usuário',
        description: 'Ocorreu um erro fazer ao criar o usuário',
      });
    }
  }

  async function handleUpdateUser(newUser: Omit<IUser, 'id'>): Promise<void> {
    try {
      const currentListUsers = users.map(currentUser => {
        if (currentUser.id !== editingUser.id) {
          return currentUser;
        }
        return {
          ...newUser,
          id: editingUser.id,
        };
      });

      const { permission } = newUser;

      await api.put(`/users/${editingUser.id}`, {
        permission,
      });

      setUsers(currentListUsers);

      addToast({
        type: 'success',
        title: 'Usuário atualizado com sucesso',
      });
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro na edição do usuário',
        description: 'Ocorreu um erro ao editar o usuário',
      });
    }
  }

  async function handleDelete(id: string) {
    try {
      await api.delete(`/users/${id}`);

      setUsers(users.filter(product => product.id !== id));

      addToast({
        type: 'success',
        title: 'Usuário excluído com sucesso',
      });
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro ao excluír usuário',
      });
    }
  }

  function toggleModel(): void {
    setModalOpen(!modalOpen);
  }

  function toggleEditModal(): void {
    setEditModalOpen(!editModalOpen);
  }

  function handleEditProduct(product: IUser): void {
    setEditingUser(product);
    toggleEditModal();
  }

  return (
    <>
      <SideBar />
      <div className="main-content" id="panel">
        <Header />
        {user.permission !== 'admin' ? (
          <Unauthorized>
            <h1>401</h1>
            <p>
              Ooops, parece que você não tem autorização para acessar essa
              página
            </p>
          </Unauthorized>
        ) : (
          <>
            <div className="header bg-primary pb-6">
              <div className="container-fluid">
                <div className="header-body">
                  <div className="row align-items-center py-4">
                    <div className="col-lg-6 col-7">
                      <h6 className="h2 text-white d-inline-block mb-0">
                        Usuários
                      </h6>
                      <ModalAddUser
                        isOpen={modalOpen}
                        setIsOpen={toggleModel}
                        handleAddUser={handleAddUser}
                      />
                      <ModalEditUser
                        isOpen={editModalOpen}
                        setIsOpen={toggleEditModal}
                        handleEditUser={handleUpdateUser}
                        editingUser={editingUser}
                      />
                      <nav
                        aria-label="breadcrumb"
                        className="d-none d-md-inline-block ml-md-4"
                      >
                        <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                          <li className="breadcrumb-item">
                            <Link to="/">
                              <FaHome
                                style={{ color: '#c53030' }}
                                className="fas fa-home"
                              />
                            </Link>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            Usuários
                          </li>
                        </ol>
                      </nav>
                    </div>
                    <div className="col-lg-6 col-5 text-right">
                      <button
                        type="button"
                        className="btn  btn-success"
                        onClick={() => setModalOpen(true)}
                      >
                        Cadastrar Usuário
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid mt--6">
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-header border-0">
                      <h3 className="mb-0">Usuários cadastrados</h3>
                    </div>
                    <div className="table-responsive">
                      <table className="table align-items-center table-flush">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col" className="sort" data-sort="name">
                              Nome
                            </th>
                            <th scope="col" className="sort" data-sort="budget">
                              E-mail
                            </th>
                            <th
                              scope="col"
                              className="sort"
                              data-sort="status"
                            />
                          </tr>
                        </thead>
                        <tbody className="list">
                          {users.map(eachUser => (
                            <tr key={eachUser.id}>
                              <td className="budget">{eachUser.name}</td>
                              <td className="budget">{eachUser.email}</td>
                              {/* <td>{product.created_at}</td> */}
                              <td>
                                <div className="col-lg-6 col-5 text-right">
                                  <button
                                    type="button"
                                    onClick={() => handleDelete(eachUser.id)}
                                    className="btn btn-sm btn-outline-danger"
                                  >
                                    <FiTrash2 />
                                  </button>

                                  <button
                                    type="button"
                                    className="btn btn-sm btn-neutral"
                                    style={{ color: '#223855' }}
                                    onClick={() => handleEditProduct(eachUser)}
                                  >
                                    Editar
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Users;
