import styled from 'styled-components';

export const Content = styled.div`
  padding: 48px 40px;
  display: flex;
  flex-direction: column;

  h1 {
    text-align: center;
    font-weight: 600;
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 40px;
  }

  img {
    align-self: center;
  }

  button {
    margin-top: 48px;
    align-self: flex-end;
  }
  button {
    font-weight: 600;
    border-radius: 8px;
    border: 0;
    background: #2dce89;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 300px;
    .text {
      margin-top: 20px;
    }
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 119px;
      background: #2dce89;
    }
    &:hover {
      background: #22d17b;
    }
  }
`;
