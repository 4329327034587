import styled from 'styled-components';
import { Form as Unform } from '@unform/web';

export const Form = styled(Unform)`
  padding: 48px 40px;
  display: flex;
  flex-direction: column;
  input {
    margin-top: 20px;
  }
  h1 {
    font-weight: 600;
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 40px;
  }
  button {
    margin-top: 48px;
    align-self: flex-end;
  }
  button {
    font-weight: 600;
    border-radius: 8px;
    border: 0;
    background: #2dce89;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    .text {
      padding: 16px 24px;
      margin-top: 20px;
    }
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 350px;
      background: #2dce89;
    }
    &:hover {
      background: #22d17b;
    }
  }
`;
