import styled from 'styled-components';

export const Unauthorized = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  align-items: center;
  color: #32325d;

  h1 {
    font-weight: bold;
    font-size: 200px;
  }

  p {
    font-size: 30px;
  }

  margin: 0 50px 0 50px;
`;

export const Construction = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  align-items: center;
  color: #32325d;

  img {
    height: 450px;
    width: 450px;
  }

  h1 {
    font-weight: bold;
    font-size: 40px;
  }

  p {
    font-size: 30px;
  }

  margin: 0 50px 0 50px;
`;
