import React, { useCallback } from 'react';

import { FaClock } from 'react-icons/fa';
import { Content } from './styles';
import Modal from '../Modal';
import BreakImage from '../../assets/break.svg';
import Button from '../Button';
import api from '../../services/api';

import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
}

const ModalStartBreak: React.FC<IModalProps> = ({ isOpen, setIsOpen }) => {
  const { addToast } = useToast();
  const { user } = useAuth();

  const handleSubmit = useCallback(async () => {
    try {
      setIsOpen();

      const entryTime = localStorage.getItem(
        '@AutomacaoCuritiba:breakEntryTime',
      );

      await api.post('breaks', {
        entryTime,
        returnTime: Date.now(),
      });

      localStorage.removeItem('@AutomacaoCuritiba:breakEntryTime');

      addToast({
        type: 'info',
        title: 'Intervalo encerrado',
        description: `${user.name}, você acbou de retornar do seu intervalo, bom trabalho`,
      });
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro no intervalo',
        description: 'Parece que ocorreu um erro ao retornar do intervalo',
      });
    }
  }, [addToast, user.name, setIsOpen]);

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Content>
        <h1>Você está no seu intervalo</h1>
        <img src={BreakImage} alt="Break" width={220} height={220} />
        <Button type="submit" onClick={handleSubmit}>
          Estou de volta
          <div className="icon">
            <FaClock size={24} />
          </div>
        </Button>
      </Content>
    </Modal>
  );
};

export default ModalStartBreak;
