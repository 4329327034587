import React, { useEffect, useState } from 'react';
import { FaHome } from 'react-icons/fa';

import Header from '../../components/Header';
import SideBar from '../../components/Sidebar';
import { useAuth } from '../../hooks/auth';

import { Unauthorized } from '../Posts/styles';
import { Link } from 'react-router-dom';
import api from '../../services/api';
import Avatar from '../../assets/avatar.svg';

interface IUser {
  id: string;
  name: string;
  email: string;
  avatar_url: string;
}

const PointCard: React.FC = () => {
  const { user } = useAuth();

  const [users, setUsers] = useState<IUser[]>([]);

  useEffect(() => {
    api.get('/users').then(response => {
      setUsers(response.data);
    });
  }, []);

  return (
    <>
      <SideBar />
      <div className="main-content" id="panel">
        <Header />
        {user.permission !== 'admin' ? (
          <Unauthorized>
            <h1>401</h1>
            <p>
              Ooops, parece que você não tem autorização para acessar essa
              página
            </p>
          </Unauthorized>
        ) : (
          <>
            <div className="header bg-primary pb-6">
              <div className="container-fluid">
                <div className="header-body">
                  <div className="row align-items-center py-4">
                    <div className="col-lg-6 col-7">
                      <h6 className="h2 text-white d-inline-block mb-0">
                        Cartões ponto
                      </h6>
                      <nav
                        aria-label="breadcrumb"
                        className="d-none d-md-inline-block ml-md-4"
                      >
                        <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                          <li className="breadcrumb-item">
                            <Link to="/">
                              <FaHome
                                style={{ color: '#c53030' }}
                                className="fas fa-home"
                              />
                            </Link>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            Cartões ponto
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid mt--6">
              <div className="row justify-content-center">
                <div className=" col ">
                  <div className="card">
                    <div className="card-header bg-transparent">
                      <h3 className="mb-0">Cartões pontos</h3>
                    </div>
                    <div className="card-body">
                      <div
                        className="row icon-examples"
                        style={{ justifyContent: 'space-between' }}
                      >
                        {users.map(eachUser => (
                          <div
                            className="col-xl-4 order-xl-2"
                            style={{ marginTop: 90 }}
                          >
                            <Link
                              className="card card-profile pointCardProfile"
                              to={`/cartao-ponto/${eachUser.id}`}
                            >
                              <div className="row justify-content-center">
                                <div className="col-lg-3 order-lg-2">
                                  <div className="card-profile-image">
                                    <label htmlFor="avatar">
                                      {user.avatar_url ? (
                                        <img
                                          src={eachUser.avatar_url}
                                          alt=""
                                          className="rounded-circle"
                                        />
                                      ) : (
                                        <img
                                          src={Avatar}
                                          alt=""
                                          className="rounded-circle"
                                        />
                                      )}
                                      <input
                                        type="file"
                                        id="avatar"
                                        style={{ opacity: 0, width: 0 }}
                                      />
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4" />
                              <div className="card-body pt-0">
                                <div className="text-center">
                                  <h5 className="h3">{eachUser.name}</h5>
                                  <div className="h5 font-weight-300">
                                    <i className="ni location_pin mr-2" />
                                    {eachUser.email}
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PointCard;
