import React, { useCallback, useState } from 'react';
import { FaClock } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import Avatar from '../../assets/avatar.svg';

import ModalStartBreak from '../ModalStartBreak';

const Header: React.FC = () => {
  const { user } = useAuth();
  const [modalOpen, setModalOpen] = useState(false);

  const { addToast } = useToast();

  const startBreak = useCallback(() => {
    addToast({
      type: 'info',
      title: 'Você começou seu intervalo',
      description:
        'você está no seu intervalo, quando voltar, aperte em "Estou de volta"',
    });

    localStorage.setItem(
      '@AutomacaoCuritiba:breakEntryTime',
      JSON.stringify(Date.now()),
    );

    setTimeout(() => setModalOpen(true), 2500);
  }, [addToast]);

  function toggleModal(): void {
    setModalOpen(!modalOpen);
  }

  return (
    <div className="main-content" id="panel">
      <ModalStartBreak isOpen={modalOpen} setIsOpen={toggleModal} />
      <nav className="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
        <div className="container-fluid">
          <button
            type="button"
            className="btn btn-sm btn-icon btn-success"
            style={{ padding: 10 }}
            onClick={startBreak}
          >
            <span className="btn-inner--icon">
              <FaClock />
            </span>
            <span className="btn-inner--text">Começar intervalo</span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav align-items-center  ml-md-auto " />
            <ul className="navbar-nav align-items-center  ml-auto ml-md-0 ">
              <li className="nav-item dropdown">
                <Link
                  className="nav-link pr-0"
                  to="/perfil"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="media align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      {user.avatar_url ? (
                        <img
                          src={user.avatar_url}
                          alt=""
                          className="rounded-circle"
                        />
                      ) : (
                        <img src={Avatar} alt="" className="rounded-circle" />
                      )}
                    </span>
                    <div className="media-body  ml-2  d-none d-lg-block">
                      <span className="mb-0 text-sm  font-weight-bold">
                        {user.name}
                      </span>
                    </div>
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
