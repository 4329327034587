import React, { useRef, useEffect, InputHTMLAttributes } from 'react';
import { FiAlertCircle } from 'react-icons/fi';

import { useField } from '@unform/core';

import { Error, Container } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLSelectElement> {
  name: string;
}

const SelectInput: React.FC<InputProps> = ({ name, ...rest }) => {
  const selectRef = useRef(null);
  const { registerField, fieldName, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'value',
    });
  }, [fieldName, name, registerField]);

  return (
    <Container isErrored={!!error}>
      <select
        className="form-control"
        defaultValue={defaultValue}
        ref={selectRef}
        {...rest}
      />

      {error && (
        <Error title={error}>
          <FiAlertCircle
            style={{ marginBottom: 65, marginRight: 15 }}
            color="#c53030"
            size={20}
          />
        </Error>
      )}
    </Container>
  );
};

export default SelectInput;
