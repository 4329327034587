import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
  ChangeEvent,
} from 'react';
import md from '../../../config/mdRenderer';
import { FaHome } from 'react-icons/fa';
import { FiCheckCircle } from 'react-icons/fi';

import { useAuth } from '../../../hooks/auth';
import { Link, useRouteMatch } from 'react-router-dom';
import { useToast } from '../../../hooks/toast';
import { useHistory } from 'react-router-dom';

import SideBar from '../../../components/Sidebar';
import Header from '../../../components/Header';

import { Unauthorized, Form } from './styles';
import api from '../../../services/api';
import InputForm from '../../../components/Input/InputForm';
import Button from '../../../components/Button';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import SelectInput from '../../../components/Input/SelectInput';

interface IPostParams {
  post: string;
}

interface IPost {
  id: string;
  title: string;
  description: string;
  main_category_id: string;
  content: string;
  image_url: string;
  image: string;
}

interface IPostParams {
  title: string;
  main_category_id: string;
  description: string;
}

interface IMainCategory {
  id: string;
  title: string;
}

const EditPost: React.FC = () => {
  const { params } = useRouteMatch<IPostParams>();
  const [post, setPost] = useState<IPost>();

  const { user } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [content, setContent] = useState<string>('');
  const [mainCategories, setMainCategories] = useState<IMainCategory[]>([]);

  const html = useMemo(() => md.render(post?.content || ''), [post]);

  const { addToast } = useToast();

  useEffect(() => {
    api.get('/maincategories').then(response => {
      setMainCategories(response.data);
    });
  }, []);

  useEffect(() => {
    api.get(`/posts/${params.post}`).then(response => {
      setPost(response.data);
    });
  }, [params.post]);

  async function handleSubmit(editingPost: IPostParams): Promise<void> {
    try {
      const schema = Yup.object().shape({
        title: Yup.string().required('Título obrigatório'),
        description: Yup.string().required('Descrição Obrigatóra'),
        main_category_id: Yup.string().required('Descrição Obrigatóra'),
      });

      await schema.validate(editingPost, {
        abortEarly: false,
      });

      const { title, description, main_category_id } = editingPost;

      await api.put(`/posts/${post?.id}`, {
        title,
        description,
        content,
        main_category_id,
      });

      addToast({
        type: 'success',
        title: 'Post atualizado com sucesso',
      });

      history.push('/posts');
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro na edição do Post',
        description: 'Ocorreu um erro ao editar o post',
      });
    }
  }

  const handleImageChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const data = new FormData();

        data.append('image', e.target.files[0]);

        await api.patch(`/posts/${post?.id}/image`, data);

        addToast({
          type: 'success',
          title: 'Imagem atualizada com sucesso',
        });
      }
    },
    [addToast, post],
  );

  return (
    <>
      <SideBar />
      <div className="main-content" id="panel">
        <Header />
        {user.permission !== 'admin' && user.permission !== 'marketing' ? (
          <Unauthorized>
            <h1>401</h1>
            <p>
              Ooops, parece que você não tem autorização para acessar essa
              página
            </p>
          </Unauthorized>
        ) : (
          <>
            <div className="header bg-primary pb-6">
              <div className="container-fluid">
                <div className="header-body">
                  <div className="row align-items-center py-4">
                    <div className="col-lg-6 col-7">
                      <h6 className="h2 text-white d-inline-block mb-0">
                        Posts
                      </h6>
                      <nav
                        aria-label="breadcrumb"
                        className="d-none d-md-inline-block ml-md-4"
                      >
                        <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                          <li className="breadcrumb-item">
                            <Link to="/">
                              <FaHome
                                style={{ color: '#c53030' }}
                                className="fas fa-home"
                              />
                            </Link>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            Posts
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            Editar post
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {post && (
              <div className="container-fluid mt--6">
                <div className="row justify-content-center">
                  <div className=" col ">
                    <div className="card">
                      {/* <div className="row justify-content-center"> */}
                      {/*  <div className="col-lg-3 order-lg-2"> */}
                      {/*    <div className="card-profile-image"> */}

                      {/*    </div> */}
                      {/*  </div> */}
                      {/* </div> */}
                      <div className="card-header bg-transparent">
                        <h3 className="mb-0">{post.title}</h3>
                      </div>
                      <div className="card-body">
                        <div
                          className="row icon-examples"
                          style={{ justifyContent: 'space-between' }}
                        >
                          <Form
                            ref={formRef}
                            onSubmit={handleSubmit}
                            initialData={post}
                          >
                            <h1>Editar Post</h1>
                            <div className="pl-lg-4">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <p className="form-control-label">Título</p>
                                    <InputForm
                                      name="title"
                                      className="form-control"
                                      containerStyle={{ color: '#000' }}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <p className="form-control-label">
                                      Descrição
                                    </p>
                                    <InputForm
                                      name="description"
                                      className="form-control"
                                      containerStyle={{ color: '#000' }}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <p className="form-control-label">
                                      Categoria
                                    </p>
                                    <SelectInput
                                      name="main_category_id"
                                      className="form-control"
                                    >
                                      {mainCategories.map(eachCategory => (
                                        <option value={eachCategory.id}>
                                          {eachCategory.title}
                                        </option>
                                      ))}
                                    </SelectInput>
                                  </div>
                                </div>
                                <div className="col-lg-7">
                                  <div className="form-group">
                                    <p className="form-control-label">Imagem</p>
                                    <label htmlFor="image">
                                      <img
                                        src={post.image_url}
                                        style={{
                                          height: 300,
                                          background: '#ffffff',
                                          border: '1px dashed #223855',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          marginTop: 48,
                                          outline: 0,
                                        }}
                                        alt=""
                                      />
                                      <input
                                        type="file"
                                        id="image"
                                        onChange={handleImageChange}
                                        style={{ opacity: 0, width: 0 }}
                                      />
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <div className="form-group">
                                    <p className="form-control-label">
                                      Conteúdo
                                    </p>
                                    <ReactQuill
                                      onChange={setContent}
                                      defaultValue={html}
                                      theme="snow"
                                      placeholder="Escreva o post...."
                                      style={{ color: '#000' }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <Button type="submit" className="submit-button">
                                Confirmar alterações
                                <div className="icon ml-auto">
                                  <FiCheckCircle size={24} />
                                </div>
                              </Button>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default EditPost;
