import styled from 'styled-components';
import { shade } from 'polished';

export const Content = styled.div`
  padding: 48px 40px;
  display: flex;
  flex-direction: column;

  h1 {
    text-align: center;
    font-weight: 600;
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 40px;
  }

  img {
    align-self: center;
  }

  button {
    margin-top: 48px;
    align-self: flex-end;
    transition: background-color 0.4s;
  }
  button {
    font-weight: 600;
    border-radius: 8px;
    border: 0;
    background: #c53030;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 300px;
    .text {
      margin-top: 20px;
    }
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 200px;
      background: #c53030;
      transition: background-color 0.4s;
    }

    &:hover {
      background: ${shade(0.2, '#c53030')};

      .icon {
        background: ${shade(0.2, '#c53030')};
      }
    }
  }
`;
