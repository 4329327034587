import React, { useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { FiMail, FiLock } from 'react-icons/fi';
import getValidationErrors from '../../utils/getValidationErrors';
import * as Yup from 'yup';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import Logo from '../../assets/logo.jpg';

import Input from '../../components/Input';
import Button from '../../components/Button';

import { AnimationContainer, Container, Content } from './styles';

interface SignInFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>();
  const history = useHistory();

  const { signIn, signOut } = useAuth();
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('E-mail inválido'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn({
          email: data.email,
          password: data.password,
        });

        addToast({
          type: 'success',
          title: 'Login realizado com sucesso',
        });

        history.push('/perfil');

        localStorage.setItem(
          '@AutomacaoCuritiba:entryTime',
          JSON.stringify(Date.now()),
        );

        setTimeout(() => signOut(), 86400000);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Ocorreu um erro ao fazer login, tente novamente',
        });
      }
    },
    [history, signIn, addToast, signOut],
  );

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <img src={Logo} alt="Automação Curitiba" />
          <Form onSubmit={handleSubmit}>
            <Input
              name="email"
              icon={FiMail}
              containerStyle={{ marginTop: 20 }}
              placeholder="E-mail"
            />

            <Input
              name="password"
              icon={FiLock}
              type="password"
              containerStyle={{ marginTop: 20 }}
              placeholder="Senha"
            />

            <Button type="submit">Entrar</Button>
          </Form>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default SignIn;
