import React, { InputHTMLAttributes, useRef, useEffect } from 'react';
import { FiAlertCircle } from 'react-icons/fi';

import { useField } from '@unform/core';

import { Error, Container } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  type?: string;
  containerStyle?: object;
}

const InputForm: React.FC<InputProps> = ({
  name,
  containerStyle,
  type,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { registerField, fieldName, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, name, registerField]);

  return (
    <Container isErrored={!!error}>
      <input
        style={containerStyle}
        type={type}
        className="form-control"
        defaultValue={defaultValue}
        ref={inputRef}
        {...rest}
      />

      {error && (
        <Error title={error}>
          <FiAlertCircle
            style={{ marginBottom: 65, marginRight: 15 }}
            color="#c53030"
            size={20}
          />
        </Error>
      )}
    </Container>
  );
};

export default InputForm;
