import React, { useRef, useCallback } from 'react';

import { FiCheckCircle } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Form } from './styles';
import Modal from '../Modal';
import InputForm from '../Input/InputForm';
import Button from '../Button';
import getValidationErrors from '../../utils/getValidationErrors';

interface IMainCategory {
  id: string;
  title: string;
}

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  handleEditMainCategory: (product: Omit<IMainCategory, 'id'>) => void;
  editingMainCategory: IMainCategory;
}

const ModalEditMainCategory: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  handleEditMainCategory,
  editingMainCategory,
}) => {
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: IMainCategory) => {
      try {
        const schema = Yup.object().shape({
          title: Yup.string().required('Nome obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        setIsOpen();
        await handleEditMainCategory(data);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
      }
    },
    [handleEditMainCategory, setIsOpen],
  );

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={editingMainCategory}
      >
        <h1>Editar Categoria</h1>
        <div className="pl-lg-4">
          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <p className="form-control-label">Nome</p>
                <InputForm name="title" className="form-control" />
              </div>
            </div>
          </div>
          <Button type="submit">
            Confirmar alterações
            <div className="icon">
              <FiCheckCircle size={24} />
            </div>
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalEditMainCategory;
