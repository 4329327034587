import React, { useEffect, useState } from 'react';
import { FaHome } from 'react-icons/fa';
import { FiTrash2, FiEdit2 } from 'react-icons/fi';

import { useAuth } from '../../hooks/auth';
import { Link } from 'react-router-dom';
import { useToast } from '../../hooks/toast';

import SideBar from '../../components/Sidebar';
import Header from '../../components/Header';

import { Unauthorized } from './styles';
import api from '../../services/api';

interface IPost {
  id: string;
  title: string;
  slugTitle: string;
  description: string;
  content: string;
  image_url: string;
}

const Posts: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();

  const [posts, setPosts] = useState<IPost[]>([]);

  useEffect(() => {
    api.get('/posts').then(response => {
      setPosts(response.data);
    });
  }, []);

  async function handleDelete(id: string) {
    try {
      await api.delete(`/posts/${id}`);

      setPosts(posts.filter(post => post.id !== id));

      addToast({
        type: 'success',
        title: 'Post excluído com sucesso',
      });
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro ao excluír o post',
      });
    }
  }

  return (
    <>
      <SideBar />
      <div className="main-content" id="panel">
        <Header />
        {user.permission !== 'admin' && user.permission !== 'marketing' ? (
          <Unauthorized>
            <h1>401</h1>
            <p>
              Ooops, parece que você não tem autorização para acessar essa
              página
            </p>
          </Unauthorized>
        ) : (
          <>
            <div className="header bg-primary pb-6">
              <div className="container-fluid">
                <div className="header-body">
                  <div className="row align-items-center py-4">
                    <div className="col-lg-6 col-7">
                      <h6 className="h2 text-white d-inline-block mb-0">
                        Posts
                      </h6>
                      <nav
                        aria-label="breadcrumb"
                        className="d-none d-md-inline-block ml-md-4"
                      >
                        <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                          <li className="breadcrumb-item">
                            <Link to="/">
                              <FaHome
                                style={{ color: '#c53030' }}
                                className="fas fa-home"
                              />
                            </Link>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            Posts
                          </li>
                        </ol>
                      </nav>
                    </div>
                    <div className="col-lg-6 col-5 text-right">
                      <Link className="btn btn-success" to="/novo-post">
                        Publicar novo post
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid mt--6">
              <div className="row justify-content-center">
                <div className=" col ">
                  <div className="card">
                    <div className="card-header bg-transparent">
                      <h3 className="mb-0">Posts</h3>
                    </div>
                    <div className="card-body">
                      <div
                        className="row icon-examples"
                        style={{ justifyContent: 'space-between' }}
                      >
                        {posts.map(post => (
                          <div
                            className="card card-lift--hover"
                            style={{
                              width: '18rem',
                              margin: 10,
                              transition: 'all 0.5s',
                            }}
                          >
                            <img
                              className="card-img-top"
                              src={post.image_url}
                              alt="Card cap"
                            />
                            <div className="card-body">
                              <h5 className="card-title">{post.title}</h5>
                              <p className="card-text text-dark">
                                {post.description}
                              </p>
                              <div className="col-lg-6 col-5 text-right">
                                <button
                                  type="button"
                                  onClick={() => handleDelete(post.id)}
                                  className="btn btn-sm btn-outline-danger"
                                >
                                  <FiTrash2 />
                                </button>

                                <Link
                                  to={`/editar-post/${post.slugTitle}`}
                                  className="btn btn-sm btn-outline-warning text-warning"
                                  style={{ color: '#223855' }}
                                >
                                  <FiEdit2 />
                                </Link>
                              </div>
                            </div>
                          </div>
                        ))}
                        {/* {posts.map(post => ( */}
                        {/*  <div className="col-xl-6 col-md-6" key={post.id}> */}
                        {/*    <div className="card card-stats"> */}
                        {/*      <div className="card-body"> */}
                        {/*        <div className="row"> */}
                        {/*          <div className="col"> */}
                        {/*            <h5 className="card-title text-uppercase text-muted mb-0"> */}
                        {/*              {post.title} */}
                        {/*            </h5> */}
                        {/*            <span className="h5 font-weight-bold mb-0"> */}
                        {/*              {post.description} */}
                        {/*            </span> */}
                        {/*          </div> */}
                        {/*        </div> */}
                        {/*        <p className="mt-3 mb-0 text-sm"> */}
                        {/*          <span className="text-success mr-2"> */}
                        {/*            <i className="fa fa-arrow-up" /> */}
                        {/*          </span> */}
                        {/*          <span className="text-nowrap" /> */}
                        {/*        </p> */}
                        {/*      </div> */}
                        {/*    </div> */}
                        {/*  </div> */}
                        {/* ))} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Posts;
