import React, { useCallback, useState, useEffect } from 'react';
import DayPicker, { DayModifiers } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { format, parseISO } from 'date-fns';

import Header from '../../components/Header';
import SideBar from '../../components/Sidebar';
import { useAuth } from '../../hooks/auth';

import { Unauthorized } from '../Posts/styles';
import { useRouteMatch } from 'react-router-dom';
import api from '../../services/api';

import { Container, Content, Schedule, Calendar, Section } from './styles';

interface IUserParams {
  user: string;
}

interface IBreak {
  id: string;
  entryTime: string;
  returnTime: string;
  entryBreakFormatted: string;
  returnBreakFormatted: string;
  user: {
    id: string;
    name: string;
    avatar_url: string;
  };
}

interface IUser {
  id: string;
  name: string;
  avatar_url: string;
}

interface IPointCard {
  id: string;
  entryTime: string;
  returnTime: string;
  entryPointCardFormatted: string;
  returnPointCardFormatted: string;
  user: {
    id: string;
    name: string;
    avatar_url: string;
  };
}

const UserPointCard: React.FC = () => {
  const { user } = useAuth();

  const { params } = useRouteMatch<IUserParams>();

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [breaks, setBreaks] = useState<IBreak[]>([]);
  const [pointCards, setPointCards] = useState<IPointCard[]>([]);

  const handleDateChange = useCallback((day: Date, modifiers: DayModifiers) => {
    if (modifiers.available && !modifiers.disabled) {
      setSelectedDate(day);
    }
  }, []);

  useEffect(() => {
    api
      .get<IBreak[]>(`/breaks/${params.user}`, {
        params: {
          year: selectedDate.getFullYear(),
          month: selectedDate.getMonth() + 1,
          day: selectedDate.getDate(),
        },
      })
      .then(response => {
        const breaksFormatted = response.data.map(breakTime => {
          return {
            ...breakTime,
            entryBreakFormatted: format(parseISO(breakTime.entryTime), 'HH:mm'),
            returnBreakFormatted: format(
              parseISO(breakTime.returnTime),
              'HH:mm',
            ),
          };
        });

        setBreaks(breaksFormatted);
      });
  }, [selectedDate, params.user]);

  useEffect(() => {
    api
      .get<IPointCard[]>(`/pointCards/${params.user}`, {
        params: {
          year: selectedDate.getFullYear(),
          month: selectedDate.getMonth() + 1,
          day: selectedDate.getDate(),
        },
      })
      .then(response => {
        const pointCardsFormatted = response.data.map(pointCard => {
          return {
            ...pointCard,
            entryPointCardFormatted: format(
              parseISO(pointCard.entryTime),
              'HH:mm',
            ),
            returnPointCardFormatted: format(
              parseISO(pointCard.returnTime),
              'HH:mm',
            ),
          };
        });

        setPointCards(pointCardsFormatted);
      });
  }, [selectedDate, params.user]);

  return (
    <>
      <SideBar />
      <div className="main-content" id="panel">
        <Header />
        {user.permission !== 'admin' ? (
          <Unauthorized>
            <h1>401</h1>
            <p>
              Ooops, parece que você não tem autorização para acessar essa
              página
            </p>
          </Unauthorized>
        ) : (
          <>
            <Container>
              <Content>
                <Schedule>
                  <h1>Cartão ponto</h1>

                  {pointCards.length <= 0 && (
                    <div>
                      <p>
                        Parece que o colaborador ainda não registrou entrada
                        nesse dia
                      </p>
                    </div>
                  )}

                  {pointCards.map(pointCard => (
                    <>
                      <Section>
                        <strong>
                          Horário de entrada:
                          <span
                            style={{
                              color: '#c53030',
                              fontSize: 17,
                              marginLeft: '10%',
                            }}
                          >
                            {pointCard.entryPointCardFormatted}
                          </span>
                        </strong>
                        {breaks.map(eachBreak => (
                          <Section style={{ marginLeft: 60 }}>
                            <strong>
                              Saída para Intervalo:
                              <span
                                style={{
                                  color: '#c53030',
                                  fontSize: 17,
                                  marginLeft: '10%',
                                }}
                              >
                                {eachBreak.entryBreakFormatted}
                              </span>
                            </strong>
                            <Section>
                              <strong>
                                Retorno do intervalo:
                                <span
                                  style={{
                                    color: '#c53030',
                                    fontSize: 17,
                                    marginLeft: '10%',
                                  }}
                                >
                                  {eachBreak.returnBreakFormatted}
                                </span>
                              </strong>
                            </Section>
                          </Section>
                        ))}
                        <Section>
                          <strong>
                            Horário de saída:
                            <span
                              style={{
                                color: '#c53030',
                                fontSize: 17,
                                marginLeft: '10%',
                              }}
                            >
                              {pointCard.returnPointCardFormatted}
                            </span>
                          </strong>
                        </Section>
                      </Section>
                    </>
                  ))}
                </Schedule>
                <Calendar>
                  <DayPicker
                    weekdaysShort={['D', 'S', 'T', 'Q', 'Q', 'S', 'S']}
                    disabledDays={[{ daysOfWeek: [0, 6] }]}
                    modifiers={{
                      available: { daysOfWeek: [1, 2, 3, 4, 5] },
                    }}
                    onDayClick={handleDateChange}
                    selectedDays={selectedDate}
                    months={[
                      'Janeiro',
                      'Fevereiro',
                      'Março',
                      'Abril',
                      'Maio',
                      'Junho',
                      'Julho',
                      'Agosto',
                      'Setembro',
                      'Outubro',
                      'Novembro',
                      'Dezembro',
                    ]}
                  />
                </Calendar>
              </Content>
            </Container>
          </>
        )}
      </div>
    </>
  );
};

export default UserPointCard;
