import React, { useRef, useCallback } from 'react';

import { FiCheckCircle } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Form } from './styles';
import Modal from '../Modal';
import InputForm from '../Input/InputForm';
import Button from '../Button';
import getValidationErrors from '../../utils/getValidationErrors';
import SelectInput from '../Input/SelectInput';

interface IUser {
  id: string;
  name: string;
  email: string;
  password: string;
  permission: string;
}

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  handleEditUser: (product: Omit<IUser, 'id'>) => void;
  editingUser: IUser;
}

const ModalEditUser: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  handleEditUser,
  editingUser,
}) => {
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: IUser) => {
      try {
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string().required('E-mail obrigatório'),
          permission: Yup.string().required('Permissão obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        setIsOpen();
        await handleEditUser(data);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
      }
    },
    [handleEditUser, setIsOpen],
  );

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Form ref={formRef} onSubmit={handleSubmit} initialData={editingUser}>
        <h1>Editar Usuário</h1>
        <div className="pl-lg-4">
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <p className="form-control-label">Nome</p>
                <InputForm name="name" className="form-control" disabled />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <p className="form-control-label">E-mail</p>
                <InputForm name="email" className="form-control" disabled />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <p className="form-control-label">Permissão</p>
                <SelectInput name="permission" className="form-control">
                  <option value="admin">Administrador</option>
                  <option value="salesperson">Vendedor</option>
                  <option value="marketing">Marketing</option>
                </SelectInput>
              </div>
            </div>
          </div>
          <Button type="submit">
            Confirmar alterações
            <div className="icon">
              <FiCheckCircle size={24} />
            </div>
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalEditUser;
