import styled from 'styled-components';
import Tooltip from '../../Tooltip';

interface ContainerProps {
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>``;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 36px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  svg {
    margin: 0;
  }
  span {
    background: #c53030;
    margin-left: 100px;
    color: #fff;
    margin-bottom: 50px;
    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
