import React, { useEffect, useRef, useState } from 'react';

import { FaHome } from 'react-icons/fa';
import { FiCheckCircle } from 'react-icons/fi';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { useAuth } from '../../../hooks/auth';
import { Link } from 'react-router-dom';
import { useToast } from '../../../hooks/toast';
import { useHistory } from 'react-router-dom';

import SideBar from '../../../components/Sidebar';
import Header from '../../../components/Header';

import { Unauthorized } from './styles';
import api from '../../../services/api';
import InputForm from '../../../components/Input/InputForm';
import Button from '../../../components/Button';
import { Form } from './styles';
import { FormHandles } from '@unform/core';
import Dropzone from '../../../components/Dropzone';
import SelectInput from '../../../components/Input/SelectInput';

interface IPost {
  id: string;
  title: string;
  main_category_id: string;
  description: string;
}

interface IMainCategory {
  id: string;
  title: string;
}

const CreatePost: React.FC = () => {
  const { user } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [content, setContent] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<File>();
  const [mainCategories, setMainCategories] = useState<IMainCategory[]>([]);

  const { addToast } = useToast();

  useEffect(() => {
    api.get('/maincategories').then(response => {
      setMainCategories(response.data);
    });
  }, []);

  async function handleSubmit(post: Omit<IPost, 'id'>): Promise<void> {
    try {
      const { title, description, main_category_id } = post;

      const data = new FormData();

      data.append('title', title);
      data.append('description', description);
      data.append('content', content);
      data.append('main_category_id', main_category_id);

      if (selectedFile) {
        data.append('image', selectedFile);
      }

      await api.post('/posts', data);

      addToast({
        type: 'success',
        title: 'Post publicado com sucesso',
      });

      history.push('/posts');
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro na publicação do post',
        description: 'Ocorreu um erro ao publicar o post',
      });
    }
  }

  return (
    <>
      <SideBar />
      <div className="main-content" id="panel">
        <Header />
        {user.permission !== 'admin' && user.permission !== 'marketing' ? (
          <Unauthorized>
            <h1>401</h1>
            <p>
              Ooops, parece que você não tem autorização para acessar essa
              página
            </p>
          </Unauthorized>
        ) : (
          <>
            <div className="header bg-primary pb-6">
              <div className="container-fluid">
                <div className="header-body">
                  <div className="row align-items-center py-4">
                    <div className="col-lg-6 col-7">
                      <h6 className="h2 text-white d-inline-block mb-0">
                        Posts
                      </h6>
                      <nav
                        aria-label="breadcrumb"
                        className="d-none d-md-inline-block ml-md-4"
                      >
                        <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                          <li className="breadcrumb-item">
                            <Link to="/">
                              <FaHome
                                style={{ color: '#c53030' }}
                                className="fas fa-home"
                              />
                            </Link>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            Posts
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            Novo post
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid mt--6">
              <div className="row justify-content-center">
                <div className=" col ">
                  <div className="card">
                    <div className="card-header bg-transparent">
                      <h3 className="mb-0">Posts</h3>
                    </div>
                    <div className="card-body">
                      <div
                        className="row icon-examples"
                        style={{ justifyContent: 'space-between' }}
                      >
                        <Form ref={formRef} onSubmit={handleSubmit}>
                          <h1>Novo Post</h1>
                          <div className="pl-lg-4">
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="form-group">
                                  <p className="form-control-label">Título</p>
                                  <InputForm
                                    name="title"
                                    className="form-control"
                                    containerStyle={{ color: '#000' }}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="form-group">
                                  <p className="form-control-label">
                                    Descrição
                                  </p>
                                  <InputForm
                                    name="description"
                                    className="form-control"
                                    containerStyle={{ color: '#000' }}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="form-group">
                                  <p className="form-control-label">
                                    Categoria
                                  </p>
                                  <SelectInput
                                    name="main_category_id"
                                    className="form-control"
                                  >
                                    {mainCategories.map(eachCategory => (
                                      <option value={eachCategory.id}>
                                        {eachCategory.title}
                                      </option>
                                    ))}
                                  </SelectInput>
                                </div>
                              </div>
                              <div className="col-lg-7">
                                <div className="form-group">
                                  <p className="form-control-label">Imagem</p>
                                  <Dropzone onFileUploaded={setSelectedFile} />
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="form-group">
                                  <p className="form-control-label">Conteúdo</p>
                                  <ReactQuill
                                    onChange={setContent}
                                    theme="snow"
                                    placeholder="Escreva o post...."
                                    style={{ color: '#000' }}
                                  />
                                </div>
                              </div>
                            </div>
                            <Button className="submit-button" type="submit">
                              Publicar novo post
                              <div className="icon ml-auto">
                                <FiCheckCircle size={24} />
                              </div>
                            </Button>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CreatePost;
