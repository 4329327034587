import React, { useCallback } from 'react';

import { FaSignInAlt } from 'react-icons/fa';
import { Content } from './styles';
import Modal from '../Modal';
import LogOut from '../../assets/logout.svg';
import Button from '../Button';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  handleLogOut: () => void;
}

const ModalLogOut: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  handleLogOut,
}) => {
  const handleSubmit = useCallback(async () => {
    setIsOpen();
    handleLogOut();
  }, [handleLogOut, setIsOpen]);

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Content>
        <h1>Encerrar expediente</h1>
        <img src={LogOut} alt="Break" width={220} height={220} />
        <Button type="submit" onClick={handleSubmit}>
          Sair
          <div className="icon">
            <FaSignInAlt size={24} />
          </div>
        </Button>
      </Content>
    </Modal>
  );
};

export default ModalLogOut;
