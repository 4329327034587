import React, { useState } from 'react';
import ModalLogOut from '../ModalLogOut';
import { isFriday } from 'date-fns';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import { Link } from 'react-router-dom';
import {
  FaUsers,
  FaBook,
  FaShoppingBag,
  FaRegAddressBook,
  FaUser,
  FaFile,
} from 'react-icons/fa';
import { FiPower } from 'react-icons/fi';
import api from '../../services/api';

const SideBar: React.FC = () => {
  // const [collapseOpen, setCollapseOpen] = useState(false);

  // const toggleCollapse = useCallback(() => {
  //   setCollapseOpen(true);
  // }, []);

  // const closeCollapse = useCallback(() => {
  //   setCollapseOpen(false);
  // }, []);

  const { user, signOut } = useAuth();
  const { addToast } = useToast();

  const [modalOpen, setModalOpen] = useState(false);

  async function handleLogOut(): Promise<void> {
    signOut();

    const currentDate = new Date(Date.now());

    if (isFriday(currentDate)) {
      addToast({
        type: 'success',
        title: 'Expediente encerrado',
        description: `Bom final de semana, ${user.name}. Até segunda`,
      });
    } else {
      addToast({
        type: 'success',
        title: 'Expediente encerrado',
        description: `Bom descanso, ${user.name}. Até amanhã`,
      });
    }

    const entryTime = localStorage.getItem('@AutomacaoCuritiba:entryTime');

    await api.post('/pointCards', {
      entryTime,
      returnTime: currentDate,
    });

    localStorage.removeItem('@AutomacaoCuritiba:entryTime');
  }

  function toggleModal(): void {
    setModalOpen(!modalOpen);
  }

  return (
    <nav
      className="sidenav navbar navbar-vertical  fixed-left  navbar-expand-lg navbar-light bg-white"
      id="sidenav-main"
    >
      <ModalLogOut
        isOpen={modalOpen}
        setIsOpen={toggleModal}
        handleLogOut={handleLogOut}
      />
      <div className="scrollbar-inner">
        <div className="sidenav-header  align-items-center">
          <span className="navbar-brand">
            Olá,
            <span>{user.name}</span>
          </span>
        </div>
        <div className="navbar-inner">
          <div className="collapse navbar-collapse" id="sidenav-collapse-main">
            <ul className="navbar-nav">
              {user.permission === 'admin' && (
                <>
                  {/* <li className="nav-item "> */}
                  {/*  <Link className="nav-link" to="/"> */}
                  {/*    <FaDesktop */}
                  {/*      size={18} */}
                  {/*      className="ni ni-tv-2 " */}
                  {/*      style={{ color: '#c53030' }} */}
                  {/*    /> */}
                  {/*    <span */}
                  {/*      style={{ marginLeft: '20px' }} */}
                  {/*      className="nav-link-text" */}
                  {/*    > */}
                  {/*      Dashboard */}
                  {/*    </span> */}
                  {/*  </Link> */}
                  {/* </li> */}
                  <li className="nav-item ">
                    <Link className="nav-link" to="/posts">
                      <FaBook
                        size={18}
                        className="ni ni-tv-2"
                        style={{ color: '#c53030' }}
                      />
                      <span
                        style={{ marginLeft: '20px' }}
                        className="nav-link-text"
                      >
                        Posts
                      </span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/categorias">
                      <FaFile
                        size={18}
                        className="ni ni-tv-2 "
                        style={{ color: '#c53030' }}
                      />
                      <span
                        style={{ marginLeft: '20px' }}
                        className="nav-link-text"
                      >
                        Categorias
                      </span>
                    </Link>
                  </li>
                  {/* <li className="nav-item"> */}
                  {/*  <Link className="nav-link" to="/produtos"> */}
                  {/*    <FaShoppingBag */}
                  {/*      size={18} */}
                  {/*      className="ni ni-tv-2 " */}
                  {/*      style={{ color: '#c53030' }} */}
                  {/*    /> */}
                  {/*    <span */}
                  {/*      style={{ marginLeft: '20px' }} */}
                  {/*      className="nav-link-text" */}
                  {/*    > */}
                  {/*      Produtos */}
                  {/*    </span> */}
                  {/*  </Link> */}
                  {/* </li> */}
                  <li className="nav-item">
                    <Link className="nav-link" to="/cartoes-pontos">
                      <FaRegAddressBook
                        size={18}
                        className="ni ni-tv-2 "
                        style={{ color: '#c53030' }}
                      />
                      <span
                        style={{ marginLeft: '20px' }}
                        className="nav-link-text"
                      >
                        Cartão ponto
                      </span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/usuarios">
                      <FaUsers
                        size={18}
                        className="ni ni-tv-2 "
                        style={{ color: '#c53030' }}
                      />
                      <span
                        style={{ marginLeft: '20px' }}
                        className="nav-link-text"
                      >
                        Usuários
                      </span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/perfil">
                      <FaUser
                        size={18}
                        className="ni ni-tv-2 "
                        style={{ color: '#c53030' }}
                      />
                      <span
                        style={{ marginLeft: '20px' }}
                        className="nav-link-text"
                      >
                        Perfil
                      </span>
                    </Link>
                  </li>
                </>
              )}
              {user.permission === 'salesperson' && (
                <>
                  {/* <li className="nav-item "> */}
                  {/*  <Link className="nav-link" to="/"> */}
                  {/*    <FaDesktop */}
                  {/*      size={18} */}
                  {/*      className="ni ni-tv-2 " */}
                  {/*      style={{ color: '#c53030' }} */}
                  {/*    /> */}
                  {/*    <span */}
                  {/*      style={{ marginLeft: '20px' }} */}
                  {/*      className="nav-link-text" */}
                  {/*    > */}
                  {/*      Dashboard */}
                  {/*    </span> */}
                  {/*  </Link> */}
                  {/* </li> */}
                  {/* <li className="nav-item"> */}
                  {/*  <Link className="nav-link" to="/produtos"> */}
                  {/*    <FaShoppingBag */}
                  {/*      size={18} */}
                  {/*      className="ni ni-tv-2 " */}
                  {/*      style={{ color: '#c53030' }} */}
                  {/*    /> */}
                  {/*    <span */}
                  {/*      style={{ marginLeft: '20px' }} */}
                  {/*      className="nav-link-text" */}
                  {/*    > */}
                  {/*      Produtos */}
                  {/*    </span> */}
                  {/*  </Link> */}
                  {/* </li> */}
                  <li className="nav-item">
                    <Link className="nav-link" to="perfil">
                      <FaUser
                        size={18}
                        className="ni ni-tv-2 "
                        style={{ color: '#c53030' }}
                      />
                      <span
                        style={{ marginLeft: '20px' }}
                        className="nav-link-text"
                      >
                        Perfil
                      </span>
                    </Link>
                  </li>
                </>
              )}
              {user.permission === 'marketing' && (
                <>
                  {/* <li className="nav-item "> */}
                  {/*  <Link className="nav-link" to="/"> */}
                  {/*    <FaDesktop */}
                  {/*      size={18} */}
                  {/*      className="ni ni-tv-2 " */}
                  {/*      style={{ color: '#c53030' }} */}
                  {/*    /> */}
                  {/*    <span */}
                  {/*      style={{ marginLeft: '20px' }} */}
                  {/*      className="nav-link-text" */}
                  {/*    > */}
                  {/*      Dashboard */}
                  {/*    </span> */}
                  {/*  </Link> */}
                  {/* </li> */}
                  <li className="nav-item">
                    <Link className="nav-link" to="/produtos">
                      <FaShoppingBag
                        size={18}
                        className="ni ni-tv-2 "
                        style={{ color: '#c53030' }}
                      />
                      <span
                        style={{ marginLeft: '20px' }}
                        className="nav-link-text"
                      >
                        Produtos
                      </span>
                    </Link>
                  </li>
                  <li className="nav-item ">
                    <Link className="nav-link" to="/posts">
                      <FaBook
                        size={18}
                        className="ni ni-tv-2"
                        style={{ color: '#c53030' }}
                      />
                      <span
                        style={{ marginLeft: '20px' }}
                        className="nav-link-text"
                      >
                        Posts
                      </span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/categorias">
                      <FaFile
                        size={18}
                        className="ni ni-tv-2 "
                        style={{ color: '#c53030' }}
                      />
                      <span
                        style={{ marginLeft: '20px' }}
                        className="nav-link-text"
                      >
                        Categorias
                      </span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/perfil">
                      <FaUser
                        size={18}
                        className="ni ni-tv-2 "
                        style={{ color: '#c53030' }}
                      />
                      <span
                        style={{ marginLeft: '20px' }}
                        className="nav-link-text"
                      >
                        Perfil
                      </span>
                    </Link>
                  </li>
                </>
              )}
            </ul>
            <hr className="my-3" />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <button
                type="button"
                onClick={() => setModalOpen(true)}
                style={{ border: 'none', background: '#fff' }}
              >
                <FiPower size={19} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default SideBar;
