import React, { useCallback, useRef, ChangeEvent } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';

import InputForm from '../../components/Input/InputForm';
import Button from '../../components/Button';
import SideBar from '../../components/Sidebar';
import Header from '../../components/Header';

import ProfileBackground from '../../assets/profileBackground.png';
import getValidationErrors from '../../utils/getValidationErrors';
import Avatar from '../../assets/avatar.svg';

interface ProfileFormData {
  name: string;
  email: string;
  old_password: string;
  password: string;
  password_confirmation: string;
}

const Profile: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const { user, updateUser } = useAuth();

  const handleSubmit = useCallback(
    async (data: ProfileFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatóio'),
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('E-mail inválido'),
          password: Yup.string().when('old_password', {
            is: val => !!val.length,
            then: Yup.string().required('Senha obrigatória'),
            otherWise: Yup.string(),
          }),
          old_password: Yup.string(),
          password_confirmation: Yup.string()
            .when('old_password', {
              is: val => !!val.length,
              then: Yup.string().required('Confirmação de senha obrigatóra'),
              otherWise: Yup.string(),
            })
            .oneOf([Yup.ref('password')], 'Confirmação incorreta'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const {
          name,
          email,
          old_password,
          password,
          password_confirmation,
        } = data;

        const formData = {
          name,
          email,
          ...(old_password
            ? {
                old_password,
                password,
                password_confirmation,
              }
            : {}),
        };

        const response = await api.put('/profile', formData);

        updateUser(response.data);

        addToast({
          type: 'success',
          title: 'Perfil atualizado com sucesso',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
        addToast({
          type: 'error',
          title: 'Erro na atualização do perfil',
          description:
            'Ocorreu um erro fazer ao atualizar o seu perfil, tente novamnete',
        });
      }
    },
    [addToast, updateUser],
  );

  const handleAvatarChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const data = new FormData();

        data.append('avatar', e.target.files[0]);

        api.patch('/users/avatar', data).then(response => {
          updateUser(response.data);

          addToast({
            type: 'success',
            title: 'Avatar atualizado com sucesso',
          });
        });
      }
    },
    [addToast, updateUser],
  );

  return (
    <>
      <SideBar />
      <div className="main-content" id="panel">
        <Header />
        <div
          className="header pb-6 d-flex align-items-center"
          style={{
            minHeight: '500px',
            backgroundImage: `url(${ProfileBackground})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center top',
          }}
        >
          <span className="mask bg-gradient-default opacity-8" />
          <div className="container-fluid d-flex align-items-center">
            <div className="row">
              <div className="col-lg-7 col-md-10">
                <h1 className="display-2 text-white">
                  Olá, <span>{user.name}</span>
                </h1>
                <p className="text-white mt-0 mb-5">
                  Essa é a sua página de perfil, aqui você pode alterar o seu
                  avatar, nome, email e senha
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid mt--6">
          <div className="row">
            <div className="col-xl-4 order-xl-2">
              <div className="card card-profile">
                <div className="row justify-content-center">
                  <div className="col-lg-3 order-lg-2">
                    <div className="card-profile-image">
                      <label htmlFor="avatar">
                        {user.avatar_url ? (
                          <img
                            src={user.avatar_url}
                            alt=""
                            className="rounded-circle"
                          />
                        ) : (
                          <img src={Avatar} alt="" className="rounded-circle" />
                        )}
                        <input
                          type="file"
                          id="avatar"
                          onChange={handleAvatarChange}
                          style={{ opacity: 0, width: 0 }}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4" />
                <div className="card-body pt-0">
                  <div className="text-center">
                    <h5 className="h3">{user.name}</h5>
                    <div className="h5 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      {user.email}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8 order-xl-1">
              <div className="card">
                <div className="card-header">
                  <div className="row align-items-center">
                    <div className="col-8">
                      <h3 className="mb-0">Editar perfil</h3>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <Form
                    ref={formRef}
                    initialData={{
                      name: user.name,
                      email: user.email,
                    }}
                    onSubmit={handleSubmit}
                  >
                    <h6 className="heading-small text-muted mb-4">
                      Informações do usuário
                    </h6>
                    <div className="pl-lg-4">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <p className="form-control-label">Nome</p>
                            <InputForm
                              name="name"
                              type="text"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <p className="form-control-label">E-mail</p>
                            <InputForm
                              name="email"
                              type="email"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <p className="form-control-label">Senha atual</p>
                            <InputForm
                              name="old_password"
                              type="password"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <p className="form-control-label">Nova senha</p>
                            <InputForm
                              name="password"
                              type="password"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <p className="form-control-label">
                              Confirmar nova senha
                            </p>
                            <InputForm
                              name="password_confirmation"
                              type="password"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <Button type="submit">Salvar alterações</Button>
                      </div>
                    </div>
                    <hr className="my-4" />
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
